import React, { useState, useEffect, useRef } from 'react'
import PropTypes from "prop-types"
import { Link } from 'gatsby'
import { useCurrentWidth } from '../hooks/useResize'

/**
 * FooterCopyright component
 * @param {object} param.languageCopyrightMenu
 * @returns {JSX Element}
 */
export default function FooterCopyright({ languageCopyrightMenu }) {

  const { menuItems: { nodes } } = languageCopyrightMenu;

  const windowWidth = useCurrentWidth();

  const footerMenuItem = useRef(null);
  const [footerMenuWidth, setFooterMenuWidth] = useState();

  /*
    Setting state value with element ( menu ) width + space.
    First parameter is state, second is width of element
    and third is space so element won’t shrink, when window width is smaller then element.
  */
  const setElementWidth = (setStateElementWidth, elementWidth, space) => setStateElementWidth(elementWidth + space);

  //limiting user to 6 links
  const maximumLinks = nodes.slice(0, 6);

  useEffect(() => {
    setElementWidth(setFooterMenuWidth, footerMenuItem.current.clientWidth, 125);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const resizeListener = () => {
      let timerId = null;
      clearTimeout(timerId);
      timerId = setTimeout(() => {

        setElementWidth(setFooterMenuWidth, footerMenuItem.current.clientWidth, 125);
      }, 80);
    };

    window.addEventListener('resize', resizeListener)

    //clean up function
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useCurrentWidth()])


  return (
    <div className="footer__copy-wrapper content-wrapper">
      <div className={`footer__items${footerMenuWidth <= windowWidth ? ' footer__items--big' : ''}`}
        ref={footerMenuItem}
      >
        {
          maximumLinks.map((link, index) => {
            return (
              <div className="footer__items-link" key={index}>
                <Link to={link.path}>
                  <p className="small">{link.label}</p>
                </Link>
              </div>
            )
          })
        }
      </div>
      <p className="footer__items-copyright small">© SHÍ {(new Date().getFullYear())}.</p>
    </div>
  )
}

FooterCopyright.propTypes = {
  languageCopyrightMenu: PropTypes.object
}