import React, {useState, useRef} from 'react'
import PropTypes from 'prop-types'

import SubMegaMenu from './SubMegaMenu'
import DefaultSubMenu from './DefaultSubMenu'
import { useCurrentWidth } from '../hooks/useResize'

import './PrimaryMenu.scss'
import './SubMegaMenu.scss'
import './SubDefaultMenu.scss'

export default function ParrentMenu({ index, item }) {

  const windowWidth = useCurrentWidth()

  const [isMegaMenuOpen, setisMegaMenuOpen] = useState(false)
  const [isDefaultMenuOpen] = useState(false)
  const [isElementHovered, setIsElementHovered] = useState(false);

  const menuItemRef = useRef([])

    /**
   * In app we have dynamically created menu element, so it couldn't be predictable how menu items with default child menu items will menu contains
   * So for each menu item with children ref is created, and then using index of clicked element certain submenu will be opened
   * 
   * @param {index} index 
   */
  function openSubMenu(index) {

    //Clicked menu item
    const currentMenuElement = menuItemRef.current
    // Submenu of clicked element
    const subMenuElement = menuItemRef.current.nextElementSibling

    currentMenuElement.classList.contains('open')
      ? currentMenuElement.classList.remove("open")
      : currentMenuElement.classList.add("open")

    subMenuElement.classList.contains('default-sub-menu--open')
      ? subMenuElement.classList.remove("default-sub-menu--open")
      : subMenuElement.classList.add("default-sub-menu--open")
  }

  return (
    <div className="primary-menu__menu-item-wrapper">
      <div
        className={`primary-menu__menu-item primary-menu__menu-item--top-item primary-menu__menu-item--has-child ${item.cssClasses[0] === "menu-item--mega-menu" ? 'mega-menu' : 'default-menu'}`}>
        <p className={item.cssClasses[0] === "menu-item--mega-menu"
          ? `${isMegaMenuOpen ? 'open ' : ''}${isElementHovered && windowWidth >= 1024 ? 'on-hover' : ''}`
          : `${isDefaultMenuOpen ? 'open ' : ''}${isElementHovered && windowWidth >= 1024 ? 'on-hover' : ''}`}
          onClick={() =>
            item.cssClasses[0] === "menu-item--mega-menu"
              ? windowWidth < 1024 && setisMegaMenuOpen(!isMegaMenuOpen)
              : windowWidth < 1024 && openSubMenu(index)}
          onKeyDown={(e) =>
            e.key === 'Enter' &&
              item.cssClasses[0] === "menu-item--mega-menu"
              ? windowWidth < 1024 && setisMegaMenuOpen(!isMegaMenuOpen)
              : windowWidth < 1024 && openSubMenu(index)}
          ref={menuItemRef}
          // eslint-disable-next-line  jsx-a11y/no-noninteractive-element-to-interactive-role 
          role="menu"
        >
          {item.label}
        </p>
        {item.cssClasses[0] === "menu-item--mega-menu"
          ? <SubMegaMenu childItems={item.childItems} isOpen={isMegaMenuOpen} setIsElementHovered={setIsElementHovered}/>
          : <DefaultSubMenu childItems={item.childItems} isOpen={isDefaultMenuOpen} setIsElementHovered={setIsElementHovered} />}
      </div>
    </div>
  )
}

ParrentMenu.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired
}