import React from 'react'
import { useRef, useEffect } from 'react'
import PropTypes from "prop-types"
import FooterSubMenuItem from './FooterSubMenuItem'
import { useCurrentWidth } from '../hooks/useResize'

/**
 * FooterMenuItem component
 * @param {object} param.languageMenu
 * @param {function} param.footerCallback
 * @returns {JSX Element}
 */
export default function FooterMenuItem({ languageMenu, footerCallback }) {

  const { menuItems: { nodes } } = languageMenu;

  //filtering parent elements from menu
  const menuItemParent = nodes.filter(node => node.parentDatabaseId === 0)

  const menu = useRef([]);

  useEffect(() => {
    footerCallback(menu.current[0].clientWidth + menu.current[1].clientWidth + 110); //110 is margin * 2
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const resizeListener = () => {
      let timerId = null;
      clearTimeout(timerId);

      timerId = setTimeout(() => {
        footerCallback(menu.current[0].clientWidth + menu.current[1].clientWidth + 110); //110 is margin * 2
      }, 80);
    };

    window.addEventListener('resize', resizeListener)

    //clean up function
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useCurrentWidth()])


  return (
    //iterating trough parent nodes and displaying them
    menuItemParent.map((node, index) => {
      return (
        <div key={index} className="footer__menu" ref={node => menu.current[index] = node}>
          <h6 className="footer__menu-title">{node.label}</h6>
          <FooterSubMenuItem menuItemChild={node} />
        </div>
      )
    })
  )
}

FooterMenuItem.protoTypes = {
  languageMenu: PropTypes.object
}