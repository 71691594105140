import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import { Link } from 'gatsby'

//Components 
import ParrentMenu from './ParrentMenu'

// Main menu on webiste, getting data from Primary Menu from WP admin
export default function PrimaryMenu({ menuItems }) {

  const { nodes } = menuItems

  const [topMenuItems, setTopMenuItems] = useState([])

  //Get all items without parrent id 
  function setTopMenuItemsHandler() {
    nodes.map(menuItem => {
      return (
        menuItem.parentDatabaseId === 0
          ? setTopMenuItems((topMenuItems) => [...topMenuItems, menuItem])
          : null
      )
    })
  }


  //Use effect 
  useEffect(() => {
    setTopMenuItemsHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (

    <div className="primary-menu">
      <div className="primary-menu__wrapper">
        <div className="primary-menu__menu">
          {topMenuItems.map((item, index) => {
            if (item.childItems.nodes.length !== 0) {
              return (
                <ParrentMenu index={index} item={item} key={index}/>
              )
            } else {
              return (
                <Link className={`primary-menu__menu-links primary-menu__menu-item primary-menu__menu-item--top-item`}
                  to={item.path} key={index}>
                  {item.label}
                </Link>
              )
            }
          }
          )}
        </div>
      </div>
    </div>
  )
}

PrimaryMenu.propTypes = {
  menuItems: PropTypes.object.isRequired
}