import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { useCurrentWidth } from '../hooks/useResize'
import PropTypes from "prop-types"
import FooterMenuItem from './FooterMenuItem'
import FooterContact from './FooterContact'
import FooterCopyright from './FooterCopyright'
import './Footer.scss'
import './FooterCopyright.scss';
import './FooterMenuItem.scss'

/**
 * Footer component
 * @param {object} param.menuData
 * @param {object} param.language
 *  @param {object} param.footerOptionsPage
 * @returns {JSX Element}
 */
export default function Footer({ menuData, language, footerOptionsPage }) {

  const [footerWrapperWidth, setFooterWrapperWidth] = useState();
  const [footerMenuWidth, setFooterMenuWidth] = useState();
  const wrapper = useRef();

  /**
   * this function sets state to element width.
   * As first argument takes state that need changing. Second element width and third paddin, margin etc.
   */
  const wrapperWidth = (setStateWrapperWidth, elementWidth, space) => setStateWrapperWidth(elementWidth - space)

  useEffect(() => {
    wrapperWidth(setFooterWrapperWidth, wrapper.current.clientWidth, 110) //110 is padding on tablet for wrapper
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const resizeListener = () => {
      let timerId = null;
      clearTimeout(timerId);

      timerId = setTimeout(() => {
        wrapperWidth(setFooterWrapperWidth, wrapper.current.offsetWidth, 110) //110 is padding on tablet for wrapper
      }, 80);
    };

    window.addEventListener('resize', resizeListener)

    //clean up function
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useCurrentWidth()])



  const { menus } = menuData;
  const { locale } = language;
  const { optionsPage: { footerOptions: { contactFooter } } } = footerOptionsPage;

  //checking which language is selected and passing adequate menu
  let languageMenu =
    locale === 'is_IS'
      ? menus.find(menu => menu.locations[0] === 'MENU_3')
      : menus.find(menu => menu.locations[0] === 'MENU_3___EN');

  //checking which language is selected for secondary footer and passing adequate menu
  let languageCopyrightMenu =
    locale === 'is_IS'
      ? menus.find(menu => menu.locations[0] === 'MENU_4')
      : menus.find(menu => menu.locations[0] === 'MENU_4___EN');


  return (
    <footer className="footer">
      <div className={`footer__wrapper ${footerMenuWidth <= footerWrapperWidth ? 'footer__wrapper--big ' : ''
        }content-wrapper`} ref={wrapper}>
        <FooterMenuItem languageMenu={languageMenu} footerCallback={setFooterMenuWidth} />
        <FooterContact contactFooter={contactFooter} locale={locale} />
      </div>
      <div className="footer__copy">
        <FooterCopyright languageCopyrightMenu={languageCopyrightMenu} />
      </div>
    </footer>
  )
}

Footer.propTypes = {
  menuData: PropTypes.object,
  language: PropTypes.object,
  footerOptionsPage: PropTypes.object
}