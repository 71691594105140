import React from 'react'
import PropTypes from "prop-types"
import { Link } from 'gatsby'

/**
 * FooterSubMenuItem component
 * @param {object} param.menuItemChild
 * @returns {JSX Element}
 */
export default function FooterSubMenuItem({ menuItemChild }) {

  const { childItems: { nodes } } = menuItemChild;

  return (
    <div className={`footer__menu-items${nodes.length > 7 ? '--split' : ''}`}>
      {
        //iterating trough child elements and displaying them in parent elements
        nodes.map((node, index) => {
          return (
            <div className="footer__menu-items-links" key={index}>
              <Link to={node.path}>
                {node.label}
              </Link>
            </div>
          )
        })
      }
    </div>
  )
}

FooterSubMenuItem.propTypes = {
  childNodes: PropTypes.object
}