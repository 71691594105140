import React, { useState } from 'react';
import SvgElement from '../svg-images/Cookies.svg'
import { Link } from 'gatsby';
import PropTypes from 'prop-types'

import './CookiePopUp.scss'

export default function CookiePopUp({ language }) {

  //getting wasDisplayed value from local storage. If true pop up was displayed already
  const wasDisplayed = typeof window !== 'undefined' ? window.localStorage.getItem('wasDisplayed') : '';

  //this state is used to unrender pop up completely
  const [closePopUp, setClosePopUp] = useState(wasDisplayed);
  //this state is used for setting modifier class for exit animation, before unrender
  const [popUpClassModifier, setPopUpClassModifier] = useState('');

  const isIslandic = language.locale === 'is_IS' ? true : false;

  const componentText = {
    title: isIslandic ? 'Við notum vafrakökur!' : 'We use cookies!',
    subtitle: isIslandic ? 'Vafrakökur bæta notendaupplifun. Frekari upplýsingar má finna í ' : 'This will improve your user experience. To learn more, please read our ',
    link: isIslandic ? '/personuverndarstefna' : '/en/privacy-policy',
    linkText: isIslandic ? 'persónuverndarstefnu okkar.' : 'Cookie Policy.',
    btnText: isIslandic ? 'Ég skil!' : 'Got it!'
  }

  //this function is called on button click, and sets a value in local storage and adds a value to modifier class for pop up close
  const onClose = () => {
    setPopUpClassModifier('cookie--close');

    setTimeout(() => {
      window.localStorage.setItem('wasDisplayed', true);
      setClosePopUp(closePopUp => !closePopUp);
    }, 400);
  }

  return (
    !closePopUp
    &&
    <div className={`cookie ${popUpClassModifier}`}>
      <div className="cookie__svg">
        <SvgElement />
      </div>
      <div className="cookie__content">
        <h6>{componentText.title}</h6>
        <p className="small">{componentText.subtitle}
          <Link to={componentText.link}>{componentText.linkText}</Link>
        </p>
      </div>
      <button
        onClick={onClose}
        onKeyDown={(e) => e.key === 'Enter' ? onClose() : null}
        className="cookie__btn cta-submit"
        aria-label={componentText.btnText}
      >
        {componentText.btnText}
      </button>
      <div
        aria-label='close cookie'
        className="cookie__close-btn"
        onClick={onClose}
        role="button"
        onKeyDown={(e) => e.key === 'Escape' ? onClose() : null}
        tabIndex="0"
      ></div>
    </div>
  )
}

CookiePopUp.propTypes = {
  language: PropTypes.object.isRequired
}